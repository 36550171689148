import React from "react"

const EmailIcon = () => {
  return (
    <svg
      className="h-full dark:fill-light_bg fill-dark_bg "
      viewBox="0 0 101 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.81396 26.143C1.81396 18.1546 8.28985 11.6787 16.2783 11.6787H83.7782C91.7666 11.6787 98.2425 18.1546 98.2425 26.143V74.3573C98.2425 82.3457 91.7666 88.8216 83.7782 88.8216H16.2783C8.28986 88.8216 1.81396 82.3457 1.81396 74.3573V26.143ZM16.2783 21.3216C13.6154 21.3216 11.4568 23.4802 11.4568 26.143V74.3573C11.4568 77.0201 13.6155 79.1787 16.2783 79.1787H83.7782C86.441 79.1787 88.5997 77.0201 88.5997 74.3573V26.143C88.5997 23.4802 86.441 21.3216 83.7782 21.3216H16.2783Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.7087 65.2255C51.0785 66.3165 48.9498 66.3111 47.3252 65.2118L3.50368 35.561L8.90753 27.5745L50.0418 55.4072L91.6494 27.5609L97.0127 35.5746L52.7087 65.2255Z"
      />
    </svg>
  )
}

export default EmailIcon
