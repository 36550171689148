import React from "react"

const ArenaIcon = () => {
  return (
    <svg
      className="h-full dark:fill-light_bg fill-dark_bg"
      viewBox="0 0 101 59"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M99.3023 41.2294L85.4398 30.6075C84.5913 29.9591 84.5913 28.8946 85.4398 28.2444L99.303 17.625C100.154 16.9754 100.51 15.8778 100.094 15.1873C99.678 14.4988 98.5157 14.2538 97.5142 14.6428L81.0298 21.0442C80.0277 21.4371 79.0834 20.9016 78.9331 19.8578L76.4541 2.70019C76.3012 1.65705 75.4966 0.803223 74.6634 0.803223C73.8342 0.803223 73.0302 1.65705 72.8813 2.69954L70.401 19.8584C70.2494 20.9022 69.3064 21.4364 68.303 21.0448L52.0912 14.7497C51.0858 14.3594 49.4479 14.3594 48.4452 14.7497L32.2307 21.0448C31.2279 21.4364 30.2837 20.9022 30.1334 19.8584L27.6564 2.69954C27.5035 1.65705 26.7002 0.803223 25.8703 0.803223C25.0378 0.803223 24.2325 1.65705 24.0829 2.69954L21.6026 19.8584C21.4523 20.9022 20.5087 21.4364 19.5059 21.0448L3.02213 14.6447C2.02003 14.2538 0.859667 14.5001 0.443399 15.188C0.0277961 15.8791 0.382222 16.9773 1.23072 17.6256L15.0952 28.245C15.9444 28.8953 15.9444 29.9598 15.0952 30.6087L1.23072 41.2294C0.379562 41.879 0.0271312 43.0421 0.442069 43.8123C0.859002 44.5838 2.01936 44.8937 3.02014 44.5021L19.3563 38.1058C20.3604 37.7149 21.294 38.2472 21.4343 39.291L23.791 56.5983C23.9333 57.6401 24.8669 58.4933 25.8656 58.4933C26.8631 58.4933 27.7974 57.6408 27.9403 56.5983L30.301 39.291C30.4406 38.2472 31.3782 37.7156 32.377 38.1058L48.4452 44.3932C49.4466 44.7861 51.0851 44.7861 52.0872 44.3932L68.1527 38.1058C69.1528 37.7149 70.0878 38.2472 70.2307 39.291L72.5914 56.5983C72.7323 57.6401 73.6666 58.4933 74.6627 58.4933C75.6615 58.4933 76.5958 57.6408 76.7374 56.5983L79.098 39.291C79.2417 38.2472 80.1753 37.7156 81.1754 38.1058L97.5136 44.5021C98.511 44.8937 99.6747 44.5831 100.091 43.8129C100.508 43.044 100.153 41.881 99.303 41.2314L99.3023 41.2294ZM64.0326 30.7423L51.8239 39.9062C50.9694 40.548 49.5676 40.548 48.7158 39.9062L36.5031 30.7423C35.6493 30.1005 35.6426 29.0437 36.4898 28.3922L48.7251 18.9962C49.5723 18.3446 50.9614 18.3446 51.8092 18.9962L64.0446 28.3922C64.8937 29.0431 64.8871 30.0998 64.0313 30.743L64.0326 30.7423Z" />
    </svg>
  )
}

export default ArenaIcon
